<template>
  <div style="height: inherit; position: relative; width: 100%; margin-top: 0.3rem;">
    <b-card>
      <v-select
        id="farm"
        v-model="selectedFarm"
        class="per-page-selector d-inline-block mx-100 w-100"
        :options="farmOptions"
        placeholder="축사 선택"
        :searchable="false"
        :clearable="false"
      />
    </b-card>
    <sensor-monitor
      ref="sensorMonitor"
    />
    <!-- <network-state-table
      ref="networkTable"
    /> -->
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import store from '@/store'
import vSelect from 'vue-select'
import SensorMonitor from './monitoring/SensorMonitor.vue'
import NetworkStateTable from './monitoring/NetworkStateTable.vue'

export default {
  components: {
    SensorMonitor,
    NetworkStateTable,
    vSelect,
    BCard,
  },
  data() {
    return {
      selectedFarm: '',
      farmOptions: [],
      updateTimer: null,
      cctvData: null
    }
  },
  watch: {
    selectedFarm(newVal) {
      localStorage.setItem('currentFarmId', newVal.value)
      localStorage.setItem('currentFarmName', newVal.label)
      this.updateData()
    },
  },
  async created() {
    this.getFarmOptions()
  },
  mounted() {
    
  },
  destroyed() {

  },
  methods: {
    updateData() {
      this.$refs.sensorMonitor.getSensor( this.selectedFarm.value )
    },
    
    async getFarmOptions() {
      
      this.farmOptions = await store.getters['farm/getFarmSelect']
      
      if (this.farmOptions.length > 0) {
        if (localStorage.getItem('currentFarmId') !== null) {
          this.selectedFarm = this.getLocalFarm()
        } else this.selectedFarm = this.farmOptions[0]
      } else {
        this.selectedFarm = this.getLocalFarm()
        this.farmOptions = await store.getters['farm/getFarmSelect']
      }
    },
    getLocalFarm () {
      return {
        label: localStorage.getItem('currentFarmName'),
        value: localStorage.getItem('currentFarmId'),
      }
    }
  },
}
</script>
